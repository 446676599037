<template>
    <uw-content title="线索">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.leadsCreate.Open()">新建检索</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="线索名称"></el-input>
                <crm-customer-type-select @onChange="TableSearch" v-model="search.type_id" clearable />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

                    <!-- 客户名称 -->
                    <vxe-column min-width="250" field="name" title="线索名称" fixed="left" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-paperclip" @click="$refs.leadsDrawer.Open(row.id)">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 线索来源 -->
                    <vxe-column min-width="120" field="source" title="线索来源" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.source?.map(rps => rps.name).toString() }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户级别 -->
                    <vxe-column min-width="120" field="level" title="客户级别" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.level?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户行业 -->
                    <vxe-column min-width="120" field="type" title="客户行业" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false">{{ row.type?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 联系方式 -->
                    <vxe-column min-width="160" field="phone" title="手机"></vxe-column>
                    <vxe-column min-width="160" field="phone_t" title="电话"></vxe-column>
                    <vxe-column min-width="160" field="email" title="邮箱"></vxe-column>

                    <!-- 所属员工 -->
                    <vxe-column min-width="90" field="user_admin" title="负责人">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-user" v-if="row.user_admin">{{ row.user_admin?.name }}</el-link>
                            <el-link :underline="false" icon="el-icon-user" type="info" v-if="!row.user">未分配</el-link>
                        </template>
                    </vxe-column>

                    <!-- 所在区域 -->
                    <vxe-column min-width="160" field="country" title="所在区域">
                        <template #default="{ row }">
                            {{  row.country ? row.country.map(rps => rps.name).toString() : '' }}
                        </template>
                    </vxe-column>

                    <!-- 详细地址 -->
                    <vxe-column min-width="250" field="address" title="详细地址">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-place" v-if="row.address">{{ row.address }}</el-link>
                            <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.address">未知</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <crm-leads-create ref="leadsCreate" @onChange="TableSearch()" />
        <crm-leads-drawer ref="leadsDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            loading: false,

            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null,
                type_id: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/leads/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['user_admin', 'type', 'source', 'country', 'level']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        TableEventDelete(id) {
            this.$http.post('/9api/crm/leads/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>